.upform {
  position: relative;
  width: 100%;
}

.upform input {
  width: 100% !important;
  height: 100% !important;
}

.upform button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #157347;
  color: white;
}

.locadiv {
  width: 100%;
}

.locadiv>select {
  width: 49% !important;
  height: 40px;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-spin .ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: white;
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
  animation-name: css-dev-only-do-not-override-ed5zg0-antSpinMove;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
}

.addfeatures_building {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;

}

.addfeatures_building>div {
  display: flex;
  flex-direction: column;
  margin: 0px;
  border: 1px dashed #ccc;
  height: 100px;
  width: 100px;
  max-width: 100%;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.addfeatures_building>div>* {
  margin: 0px;
  text-align: center;
}

.feature_div input {
  margin-bottom: 4px;
  width: 100%;
}

.fact_feature {
  margin-bottom: 5px !important;
}
.uploadimage_idv {
  position: relative;
  width: 100%;
}

.uploadimage_idv input {
  width: 100%;
  padding: 10px;
  /* height: 40px; */
  border-radius: 10px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadimage_idv>button {
  position: absolute;
  right: 10px;
  top: 50%;
  background-color: green;
  color: white;
  transform: translateY(-50%);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  bottom: none;
}

.basic_add_div {
  width: 500px;
  max-width: 100%;
  margin: auto;
  height: 500px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-around;
  flex-wrap: wrap;
  border-radius: 10px;
}

.basic_add_div>div {
  width: 100px;
  max-width: fit-content;
  border: 1px solid #ccc;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  flex-direction: column;
  border-radius: 50%;
  justify-content: space-around;
}

.basic_add_div>div>h6 {
  text-align: center;
}
.filter_inputs>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.filter_inputs>div>div {
  width: 48%;
}

.filter_inputs>div>div input {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  outline: none;
}

.filter_inputs>button {
  margin: auto;
  display: block;
  width: fit-content;
  margin-bottom: 10px;
  text-transform: capitalize;
  letter-spacing: 2px;
}
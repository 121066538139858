.range-slider.margin-lg {
  margin: 20px auto;
}

/* Yellow Range Slider */



#range-slider-yellow .range-slider__thumb[data-active] {
  transform: translate(-50%, -50%) scale(1.25);
}

#range-slider-yellow .range-slider__range[data-active] {
  height: 16px;
}

/* AB Range Slider */
#range-slider-ab {
  height: 16px;
}





#range-slider-ab .range-slider__thumb[data-upper] {
  background: url('../../../assets/images/car.png');
  background-position: center center;
  width: 100px;
  height: 60px;
  border-radius: 4px;
}

#range-slider-ab .range-slider__thumb[data-lower] {
  /* background: var(--main-color-one); */

}

#range-slider-ab .range-slider__thumb[data-lower][data-active] {
  animation: rotate-anti-clockwise 0.9s infinite;
}

#range-slider-ab .range-slider__thumb[data-upper][data-active] {
  animation: rotate-clockwise 0.9s infinite;
}


.range-slider .range-slider__range {
  /* background: var(--main-color-one) !important; */
}

.range-slider input[type="range"] {
  -webkit-appearance: none;
  pointer-events: none;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  background-color: transparent;
  display: none;
}

.property_types {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.property_types .property_type {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.property_types .property_type>div {
  width: fit-content;
  padding: 5px 10px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
  border-radius: 10px;
  font-size: 30px;
  cursor: pointer;
}

.property_types .property_type>div.active {
  border-color: blue;
  border: 1px solid blue !important;
}

.bedrooms_numbers {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bedrooms_numbers .bedrooms_number {
  border: 1px solid #ccc;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
}

.bedrooms_numbers .bedrooms_number.active {
  border: 1px solid blue;
  color: blue;

}

.bathrooms_numbers {
  display: flex;
  align-items: center;
  gap: 10px;
}

.bathrooms_numbers .bathroom_number {
  border: 1px solid #ccc;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
}

.bathrooms_numbers .bathroom_number.active {
  border: 1px solid blue;
  color: blue;

}

.square_metter {
  margin: 20px 0px;
}

.square_metter .min_max_square {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.min_square {
  display: flex;
  align-items: center;
  gap: 10px;
}

.max_square {
  display: flex;
  align-items: center;
  gap: 10px;
}

.min_max_square .min_square input {
  width: 140px;
  height: 40px;
  border-radius: 20px;
  padding: 10px;
  border: 1px solid #ccc;
}

.min_max_square .max_square input {
  width: 140px;
  height: 40px;
  border-radius: 20px;
  padding: 10px;
  border: 1px solid #ccc;
}

.delivary_date {
  margin: 20px 0px;
}

.delivary_date .delivaries {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.delivary_date .delivaries .delivary {
  border: 1px solid #ccc;
  padding: 3px 10px;
  border-radius: 10px;
  cursor: pointer;
  text-transform: capitalize;
}

.delivary_date .delivaries .delivary.active {
  border: 1px solid blue;
  color: blue;
}

.sales_type {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sales_type .sale_type {
  border: 1px solid #ccc;
  padding: 3px 10px;
  border-radius: 10px;
  cursor: pointer;
  text-transform: capitalize;
}

.sales_type .sale_type.active {
  border: 1px solid blue;
  color: blue;

}

.finishing_div {
  margin: 10px 0px;
}

.finishing_div h6 {
  text-transform: capitalize;
}

.finishing_div .finishings {
  display: flex;
  align-items: center;
  gap: 10px;
}

.finishing_div .finishings .finishing {
  border: 1px solid #ccc;
  padding: 3px 10px;
  border-radius: 10px;
  cursor: pointer;
  text-transform: capitalize;
}

.finishing_div .finishings .finishing.active {
  border: 1px solid blue;
  color: blue;

}

.payment_paln_div {
  margin: 10px 0px;
}

.pay_methods {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pay_methods .down_payment {
  width: 60%;
}

.pay_methods .down_payment input {
  width: 80%;
  height: 40px;
  border-radius: 20px;
  padding: 10px;
  border: 1px solid #ccc;
}

.pay_methods .monthly_installment {
  width: 38%;
}

.pay_methods .monthly_installment input {
  width: 80%;
  height: 40px;
  border-radius: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  margin-right: 3px;
}

.installment_years .installments {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.installment_years .installments .install {
  border: 1px solid #ccc;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
}

.installment_years .installments .install.active {
  border: 1px solid blue;
  color: blue;

}

.select_compund {
  margin: 10px 0px;
}

.select_compund .add_compound {
  border: 1px solid #ccc;
  width: 80%;
  margin: auto;
  padding: 10px;
  text-align: center;
  border-radius: 20px;
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}

.select_compund .add_compound span {
  border: 1px solid black;
  background-color: #ddd;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sullocation {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sullocation select {
  width: 50% !important;
}

.resile_div {
  margin-top: 20px;
}

.resile_div>div {
  margin-bottom: 10px;
}

.resile_div>div label {
  display: block;
  margin-bottom: 3px;
}

.resile_div>div input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;

}
.col-lg-4 {
  width: 32.3% !important;
}

form {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

form input {
  width: 100%;
  height: 40px;
  padding: 10px;
  border-radius: 15px;
  border: 1px solid #ccc;
}

.methods_btns {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}

.methods_btns>button {
  width: 100px;
  height: 30px;
  border-radius: 10px;
  border: none;
}

.methods_btns>button.active {
  background-color: #0b5ed7;
  color: white;
}

form label {
  width: 100%;
}

form select {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: transparent;
}
.colds_leads p {
  background-color: #ccc;
  padding: 10px;
  cursor: pointer;
  width: 200px;
  text-align: center;
  border-radius: 10px;
}

.colds_leads p.active {
  background-color: #0b5ed7;
  color: white;
}

.table-nowrap td {
  white-space: normal;
}
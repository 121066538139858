.assignform {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100% !important;
  flex-wrap: 'wrap',
}

.assignform>* {
  width: 100%;
}

.assignform>button {
  width: fit-content;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}